<template>
  <section class="home-why">
    <div class="container section-value-props hidden">
      <div class="section-header">
        <span class="subheading">{{$t('why.title')}}</span>
        <h2 class="heading-secondary">{{$t('why.header')}}</h2>
      </div>
      <div class="grid grid--3-cols">
        <div class="value-box hidden">
          <img src="/assets/invrs-why1.png" class="value-icon" />
          <h3 class="heading-tertiary">{{$t('why.cultivate')}}</h3>
          <p class="value-description">
            {{$t('why.cultivateText')}}
          </p>
        </div>
        <!-- <div class="value-box hidden">
          <img src="../../assets/save-money.png" class="value-icon" />
          <h3 class="heading-tertiary">Save money</h3>
          <p class="value-description">
            Keep your money. Earn premium data and tools for free by using the
            app.
          </p>
        </div> -->
        <div class="value-box hidden">
          <img src="/assets/invrs-why2.png" class="value-icon" />
          <h3 class="heading-tertiary">{{$t('why.strengthen')}}</h3>
          <p class="value-description">
           {{$t('why.strengthenText')}}
          </p>
        </div>
        <div class="value-box hidden">
          <img src="/assets/invrs-why3.png" class="value-icon" />
          <h3 class="heading-tertiary">{{$t('why.invest')}}</h3>
          <p class="value-description">
            {{$t('why.investText')}}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      valueProps: [],
      obsOptionsValueProps: {
        root: null,
        threshold: 0.3,
      },
      obsOptionsSection: {
        root: null,
        threshold: 0.15,
      },
      section: "",
    };
  },
  mounted() {
    this.valueProps = document.querySelectorAll(".value-box");
    this.section = document.querySelector(".section-value-props");
    const valuePropsObserver = new IntersectionObserver(
      this.revealValueProps,
      this.obsOptionsValueProps
    );
    const sectionObserver = new IntersectionObserver(
      this.revealSection,
      this.obsOptionsSection
    );
    valuePropsObserver.observe(this.section);
    sectionObserver.observe(this.section);
  },
  methods: {
    revealValueProps(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.valueProps.forEach((v, i) => {
            setTimeout(() => v.classList.remove("hidden"), i * 100);
          });
        }
      });
    },
    revealSection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.section.classList.remove("hidden");
        }
      });
    },
  },
};
</script>

<style scoped>
.home-why {
  padding: 9.6rem 0;
}

::v-deep(.container) {
  max-width: 180rem;
}

.section-value-props {
  transition: all 0.3s ease-in;
}

.value-description {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

.value-icon {
  margin-bottom: 1.6rem;
  width: 8.6rem;
  height: 6.4rem;
}

.section-header {
  display: flex;
  flex-direction: column;
}

.value-box {
  transition: all 0.3s ease-in;
}

.hidden {
  transform: translateY(4.8rem);
  opacity: 0;
}

/* **************************** */
/* BELOW 976px (landscape tablets) */
/* **************************** */
@media (max-width: 61em) {
  .home-why {
    padding: 9.6rem 0 9.6rem 0;
  }
}

/* **************************** */
/* BELOW 592px (mobile) */
/* **************************** */
@media (max-width: 37em) {
  .home-why {
    padding: 9.6rem 9.6rem 0;
  }
}

/* **************************** */
/* BELOW 496px (mobile) */
/* **************************** */
@media (max-width: 31em) {
  .value-icon {
    width: 5.2rem;
    height: 5.2rem;
  }

  .home-why {
    padding: 9.6rem 0 9.6rem 0;
  }
}
</style>
